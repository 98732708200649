<template>
  <b-modal
    id="modal__SpecificationCreate"
    title="Create Specification"
    size="lg"
    :ok-title="loading ? 'Loading...' : 'Create Specification'"
    :ok-disabled="loading"
    ok-variant="success"
    cancel-title="Discard"
    cancel-variant="outline-secondary"
    lazy
    no-fade
    @ok.prevent="onSubmit"
    @cancel="onCancel"
  >
    <SpecificationForm v-model="formFields" />
  </b-modal>
</template>

<script>
import store from '@/store'
import SpecificationForm from '@/views/Specifications/components/SpecificationForm.vue'
import { ref } from '@vue/composition-api'

export default {
  name: 'CreateSpecificationModal',
  components: { SpecificationForm },
  setup(props, context) {
    const loading = ref(false)
    const formFields = ref({
      title: '',
      description: '',
      prefix: '',
    })

    const onSubmit = () => {
      loading.value = true
      store
        .dispatch('specification/createSpecification', formFields.value)
        .then(response => {
          if (response.status === 201) {
            context.root.$bvModal.hide('modal__SpecificationCreate')
            context.emit('specification-created', response.data.id)
            formFields.value = {
              title: '',
              description: '',
              prefix: '',
            }
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    const onCancel = () => {
      formFields.value = {
        title: '',
        description: '',
        prefix: '',
      }
    }

    return {
      loading,
      formFields,
      onSubmit,
      onCancel,
    }
  },
}
</script>

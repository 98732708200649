<template>
  <div id="SpecificationsView">
    <div class="w-100 d-inline-flex justify-content-between align-items-center mb-1">
      <h2>Specifications</h2>
      <b-button-group>
        <b-dropdown
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          text="Import Specification"
          variant="outline-primary"
          size="sm"
        >
          <b-dropdown-item @click="$bvModal.show('import-requirements-modal')">
            CSV
          </b-dropdown-item>
          <b-dropdown-item @click="$router.push({ name: 'import_ibm_doors_module', params: { modelId: $store.state.model.id } })">
            IBM DOORs Next
          </b-dropdown-item>
          <b-dropdown-item>
            Atlassian JIRA
          </b-dropdown-item>
        </b-dropdown>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-success"
          size="sm"
          @click="openCreateModal"
        >
          <feather-icon icon="PlusIcon" />
          Create Specification
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-info"
          size="sm"
          @click="fetchSpecifications"
        >
          <feather-icon icon="RefreshCwIcon" />
        </b-button>
      </b-button-group>
    </div>

    <div v-if="loading">
      <div class="w-100 d-inline-flex justify-content-center mt-4">
        <div class="d-flex flex-column align-items-center">
          <h5 class="text-warning mb-2">
            <atom-spinner class="animate-pulse" />
          </h5>
          <h5>
            Loading Specifications...
          </h5>
        </div>
      </div>
    </div>
    <div v-else class="w-100 d-flex justify-content-around">
      <specification-table-view
        class="w-100"
        :specifications="specifications"
        @openDedicated="openSpecInDedicatedPage"
        @openLegacyRequirements="openLegacyRequirementsTable"
        @openRequirements="openRequirementsTable"
        @showModalUpdate="openUpdateModal"
        @showModalCopy="openCopyModal"
        @showModalDelete="openDeleteModal"
      />
    </div>

    <div id="SLV_Modals">
      <CreateSpecModal @submit="fetchSpecifications" />
      <UpdateSpecModal @submit="fetchSpecifications" />
      <CopySpecModal @submit="fetchSpecifications" />
      <DeleteSpecModal @submit="fetchSpecifications" />
      <import-requirement-modal />
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import { computed, ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import AtomSpinner from '@/components/Spinners/AtomSpinner.vue'
import ImportRequirementModal from '@/views/RequirementsTableLegacy/modals/Import.vue'
import CopySpecModal from './components/modals/CopySpecModal.vue'
import CreateSpecModal from './components/modals/CreateSpecModal.vue'
import DeleteSpecModal from './components/modals/DeleteSpecModal.vue'
import UpdateSpecModal from './components/modals/UpdateSpecModal.vue'
import SpecificationTableView from './components/SpecificationTableView.vue'

export default {
  name: 'Specifications',
  directives: { Ripple },
  components: {
    AtomSpinner,
    SpecificationTableView,
    CreateSpecModal,
    UpdateSpecModal,
    CopySpecModal,
    DeleteSpecModal,
    ImportRequirementModal,
  },
  setup(props, context) {
    const { router, route } = useRouter()

    // Note: 'props' is required even though it's detected as unused by the IDE.
    const loading = ref(false)
    const specifications = computed(() => store.state.specification.list)

    const fetchSpecifications = () => {
      loading.value = true
      store
        .dispatch('specification/getAllSpecifications')
        .then(() => {
          store.dispatch('specification/getSpecificationListMetadata')
        })
        .finally(() => { loading.value = false })
    }
    fetchSpecifications()

    const openSpecInDedicatedPage = specObj => {
      store.commit('app/SET_DYNAMIC_PAGE_TITLE', specObj.title)
      store
        .dispatch('specifications/selectSpecification', specObj.id)
        .then(() => {
          router.push({
            name: 'specification_page',
            params: {
              modelId: sessionStorage.getItem('kompozition-workspace'),
              specId: specObj.id,
            },
          })
        })
        .catch(e => console.error(e))
    }
    const openLegacyRequirementsTable = specObj => {
      store
        .dispatch('requirementsTableLegacy/setSpecification', specObj.id)
        .then(() => {
          router.push({
            name: 'legacy_requirements_table',
            params: { ...route.params, specId: specObj.id },
          })
        })
        .catch(e => console.error(e))
    }
    const openRequirementsTable = specObj => {
      router.push({
        name: 'requirements_table',
        params: { ...route.params, specId: specObj.id },
      })
    }

    const __openModal = (specObj, modalId) => {
      store
        .dispatch('specification/getSpecification', specObj.id)
        .then(() => {
          context.root.$bvModal.show(modalId)
        })
    }
    const openCreateModal = () => {
      context.root.$bvModal.show('modal__SpecificationCreate')
    }
    const openUpdateModal = specObj => {
      __openModal(specObj, 'modal__SpecificationUpdate')
    }
    const openDeleteModal = specObj => {
      __openModal(specObj, 'modal__SpecificationDelete')
    }
    const openCopyModal = specObj => {
      __openModal(specObj, 'modal__SpecificationCopy')
    }

    return {
      loading,
      specifications,
      fetchSpecifications,

      openSpecInDedicatedPage,
      openLegacyRequirementsTable,
      openRequirementsTable,
      openCreateModal,
      openUpdateModal,
      openCopyModal,
      openDeleteModal,
    }
  },
}
</script>

<template>
  <b-form>
    <b-form-group
      label="Title"
      label-for="specification-title"
    >
      <b-form-input
        id="specification-title"
        v-model="value.title"
        placeholder="Specification title"
        @input="val => $emit('input', { ...value, ...{ title: val } })"
      />
    </b-form-group>

    <b-form-group
      label="Prefix"
      label-for="specification-prefix"
    >
      <b-form-input
        id="specification-prefix"
        v-model="value.prefix"
        placeholder="ABC-"
        @input="val => $emit('input', { ...value, ...{ prefix: val } })"
      />
    </b-form-group>

    <b-form-group
      label="Description"
      label-for="specification-description"
    >
      <b-textarea
        id="specification-description"
        v-model="value.description"
        placeholder="Give a brief description of the Specification..."
        rows="6"
      />
    </b-form-group>
  </b-form>
</template>

<script>
export default {
  name: 'SpecificationForm',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
}
</script>
